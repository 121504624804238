import React from "react";
import Link from "next/link";
import classNames from "classnames";
import {FormattedMessage} from "react-intl";
import defaultImage from "../../images/defaultpic.webp";
import {IBlogDataPost} from "../../types/pages";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {createRightDate, postOrBlog} from "../../services/utils";


interface IProps {
    post: IBlogDataPost,
    dbName?: string,
    layout: {
        [key: string]: string
    },
    forBLogType?: string,
    domain: string,
}

function PostCard(props: IProps) {
    const {post, layout, domain, dbName, forBLogType} = props;
    const cardClasses = classNames("post-card", {
        "post-card--layout--grid": ["grid-nl", "grid-lg"].includes(layout.classic),
        "post-card--layout--list": ["list-nl", "list-sm"].includes(layout.classic),
        "post-card--size--nl": ["grid-nl", "list-nl"].includes(layout.classic),
        "post-card--size--lg": layout.classic === "grid-lg",
        "post-card--size--sm": layout.classic === "list-sm",
    });
    const mainPost = postOrBlog(forBLogType, post);


    let rightDay = post.published_at ? createRightDate(post.published_at) : createRightDate(post.created_at)

    const content = (
        <div className={cardClasses}>
            <div className="post-card__image">
                <LazyLoadImage
                    alt="post-image"
                    src={`${post.image
                        ? `/storage/${domain}/` + post.image
                        : defaultImage.src
                    } `}
                    // layout="fill"
                />
            </div>
            <div className="post-card__info">
                <div className="post-card__name">
                    {/*<span>{post.blog_title.length > 30 ? `${post.blog_title.slice(0, 30)}...` : post.blog_title}</span>*/}
                    {/*<span>{forBLogType === "blog" ? post.blog_title : post.name}</span>*/}
                    <span>{mainPost.title}</span>
                </div>
                <div className="post-card__read-data">
                    <div className={"post-card__date"}>{rightDay}</div>
                    <div className="post-card__read-more">
                        <FormattedMessage id="read_more" defaultMessage=" Read More"/>
                    </div>
                </div>
            </div>
        </div>
    )
    // const truePath = forBLogType === "blog" ? `/blog/${post.url_key}` : `/post/${post.slug}`

    return (
        // <Link href={truePath}>
        <Link href={mainPost.url}>
            <a>{content}</a>
        </Link>
    );
}

export default PostCard;
