import React, {FC} from 'react'
///import { selectHyperLink }            from '../../../redux/mySlice'
import {convertStyles} from "../../../services/utils"

interface IProps {
    item: any
}

const HyperLink: FC<IProps> = ({item}) => {

    const setStyles = convertStyles(item.settings) || {};
    return (
        <div
            //@ts-ignore
            data-aos={setStyles?.animationAos['data-aos']}
            //@ts-ignore
            style={{...setStyles.parentStyles}}
            // style={{...setStyles.parentStyles}}
        >
            <a
                href={item.hyperLink}
                target={"_blank"}
                /*@ts-ignore*/
                style={{...setStyles.styles}}
                rel="noreferrer"

            >
                {item.hyperLinkName}
            </a>
        </div>
    )
}

export default HyperLink;
