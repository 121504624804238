import React, {useState} from "react";

import {convertStyles} from "../../../services/utils"
import AccordionLIst from './AccordionLIst'

export default function AccordionComponent({item}) {
    const setStyle = {margin: '0'}
    let setStyles = convertStyles(item.settings) || {};

    const [active, setActive] = useState(null);

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }

    return (
        <>
            <div
                key={item.id}
                data-aos={setStyles?.animationAos['data-aos']}
                style={{...setStyles.parentStyles}}
                // style={{...setStyles.parentStyles} as CSSProperties | undefined}
            >

                <div className="card-faq">
                    {item.title}
                    {item.description}
                    {item.accordionFaq.map((faq, index, item) => {
                        return (
                            <AccordionLIst
                                key={index}
                                active={active}
                                handleToggle={handleToggle}
                                setStyles={setStyles.styles}
                                faq={faq}
                            />
                        )
                    })
                    }
                </div>
            </div>

        </>

    );
}
