import React, {CSSProperties, FC} from "react";
import {convertStyles} from "../../../services/utils"

interface IProps {
    item: {
        buttonValue: string;
        settings: {};
        linkValueButton: {
            id: string;
            value: string;
            checked: boolean
        }
    }
}


const Button: FC<IProps> = ({item}) => {
    let tagEntry = `<p>${item.buttonValue}</p>`;
    const setStyles = item.settings ? convertStyles(item.settings) : {parentStyles: {"text-align": ''}};
    return (
        <div
            //@ts-ignore
            data-aos={setStyles?.animationAos['data-aos']}
            style={{...setStyles.parentStyles} as CSSProperties | undefined}
        >
            <a
                href={item.linkValueButton?.value ? item.linkValueButton.value : "/"}
                target={item.linkValueButton?.checked ? "_blank" : ""}
                rel={"noreferrer"}
            >
                <button
                    //@ts-ignore
                    style={{...setStyles.styles}}
                    dangerouslySetInnerHTML={{
                        __html: tagEntry,
                    }}
                />
            </a>
        </div>
    );
}

export default Button;
