import React from "react";
import {convertStyles, createBuilderData} from "../../services/utils"
import {LazyLoadImage} from "react-lazy-load-image-component";
import {FC} from "preact/compat";
import Link from "next/link";

interface IProps {
    item: any
}

const ImageUploadingApp: FC<IProps> = ({item}) => {
    const setStyles = convertStyles(item.settings) || {};
    let ImageStyles;
    const fullScreen = item?.settings.find(
            (
                item: { fullScreen: { tabId: number, fullScreen: boolean } }
            ) => item.fullScreen?.fullScreen
        )?.fullScreen;

    if (fullScreen?.tabId === item.tab) {
        ImageStyles = {width: "150%", marginLeft: "-25%"}
    }
    return (
        <div
            //@ts-ignore
            data-aos={setStyles?.animationAos['data-aos']}
            //@ts-ignore
            style={{...setStyles.parentStyles}}
            //@ts-ignore
            // style={{...setStyles.parentStyles}}
        >
            {item.hasOwnProperty("linkValue")
                ?
                <Link href={item.linkValue.value} target={item.isLinkChecked ? "_blank" : "_self"}>
                    <a>
                        <LazyLoadImage
                            /*@ts-ignore*/
                            style={{...setStyles.styles}}
                            src={item.images[1]?.imageUpload}
                        />
                    </a>

                </Link>
                :
                <LazyLoadImage
                    /*@ts-ignore*/
                    style={{...setStyles.styles, ...ImageStyles}}
                    src={item.images[1]?.imageUpload}
                />
            }


        </div>
    )
        ;
}

export default ImageUploadingApp;