import React, {CSSProperties, FC} from "react";
import {IBlogDataPost} from "../../../types/pages";
import PostCard from "../../shared/PostCard";
import {useSelector} from "react-redux";
import {IState} from "../../../types/state";
import classNames from "classnames";

interface IProps {
    item: {
        buttonValue: string;
        settings: {};
        blogs: Array<any>;
        linkValueButton: {
            id: string;
            value: string;
            checked: boolean
        }
    },
    domain: string
}


const BlogsComponent: FC<IProps> = ({item}) => {


    const domain = useSelector((state: IState) => state.general.domain)
    const dbName = useSelector((state: IState) => state.general.dbName)
    const postsList = item.blogs.map((post: IBlogDataPost) => {
        const postLayout: {
            [key: string]: string
        } = {
            classic: "grid-lg",
            grid: "grid-nl",
            list: "list-nl",
        }

        //
        // schemaBlog.offers.push({
        //     id: post.id,
        //     image: `https://${props.domain}/storage/${props.dbName}/${logoPath}`,
        //     name: post.name,
        //     description: post.short_description,
        // });

        return (
            <div key={post.id} className={"posts-list__item"}>
                <PostCard
                    forBLogType={"post"}
                    post={post}
                    dbName={dbName}
                    layout={postLayout}
                    domain={domain}
                />
            </div>
        );
    });

    const postClasses = classNames("posts-list__body", {
        "column__one": postsList.length === 1,
        "column__second": postsList.length === 2,
        "column__third": postsList.length === 3,
    });
    return (
        <div>
            {postsList.length ? (
                    <div className="block">
                        <div className="posts-view">
                            <div
                                className={`posts-view__list posts-list posts-list--layout--`}
                            >
                                {/*<div className="posts-list__body">{postsList}</div>*/}
                                <div className={postClasses}>{postsList}</div>
                            </div>
                        </div>
                    </div>
                )
                : ""
            }
        </div>
    );
}

export default BlogsComponent;