import {createBuilderData, idGen} from "../../services/utils";
import React from "react";
import Heading from "./heading/Heading";
import HyperLink from "./hyperLink/HyperLink";
import Editor from "./Editor/Editor";
import AccordionComponent from "./Accordion/Accordion";
import ImageUploadingApp from "./ImageUploading";
import Button from "./Button/Button";
import VideoComponent from "./VideoComponent/VideoComponent";
import BlogsComponent from "./BlogsComponent/BlogsComponent";
export default function BuilderSection({
                                           tree,
                                           tab,
                                           componentsData,
                                           initialLayout,
                                           domain
                                       }: any) {

    function renderData(data: any) {
        switch (data?.content) {
            case "Heading":
                return <Heading item={data}/>

            case "HyperLink":
                return <HyperLink item={data}/>

            case "Editor":
                return <Editor item={data}/>

            case "Accordion":
                return <AccordionComponent item={data}/>

            case "ImageUpload":
                return <ImageUploadingApp item={data}/>

            case "Button":
                return <Button item={data}/>
            case "Video":
                return <VideoComponent item={data}/>
            case "Blogs":
                return <BlogsComponent domain={domain} item={data} />
            default:
                return null;
        }
    }

    const renderMapData = (forMap = "class") => {
        if (initialLayout && initialLayout.length) {
            return initialLayout?.map((item: any) => {
                return Object.keys(item).map((element) => {

                    return item[Number(element)].map((child: any) => {
                        if (forMap === "backgroundColor") {
                            return child.backgroundColor
                        }
                        return child.children.map((children: any) => {
                            return children.class
                        })
                    })
                })
            })
        }
    }

    const renderClass = renderMapData("class")
    const renderRowBackground = renderMapData("backgroundColor")

    return Object.values(componentsData).length > 0 && tree[tab] && Object.values(tree[tab]).length > 0 && (
        <div
            className="block-products block-products--layout--large-first"
            style={{marginBottom: 0}}
        >
            <div
                className="container home-product-container b-container"
            >
                {

                    tree[tab]?.map((item: any, ind: number) => {
                        if (item?.length === 1 && item?.[0]?.length === 1) {
                            return (
                                <div
                                    key={idGen()}
                                    // this is only for lonely elements
                                    style={{background: renderRowBackground?.[0]?.[tab - 1]?.[ind] ?? "#ffffff"}}
                                    className="zg-grid-col zg-col-lg zg-col-sm zg-col-md zg-col-xs zg-col-sm"
                                >
                                    {renderData(createBuilderData(componentsData, item[0][0]))}
                                </div>
                            )
                        } else {
                            return (
                                <div
                                    key={idGen()}

                                    className={`zg-row ${!Boolean(createBuilderData(componentsData, item[0][0])?.fullScreen) && "container"}  builder_component`}
                                    style={{background: renderRowBackground?.[0]?.[tab - 1]?.[ind] ?? "#ffffff"}}
                                >
                                    {
                                        item?.map((element: any, index: number) => {
                                            if (element?.length === 1) {
                                                return (
                                                    <div
                                                        key={idGen()}
                                                        // className="zg-grid-col zg-col-lg zg-col-sm zg-col-md zg-col-xs zg-col-sm"
                                                        className={renderClass?.[0]?.[tab - 1]?.[ind]?.[index]}
                                                    >
                                                        {renderData(createBuilderData(componentsData, element[0]))}
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div
                                                        key={idGen()}
                                                        // className="zg-grid-col zg-col-lg zg-col-sm zg-col-md zg-col-xs zg-col-sm"
                                                        className={renderClass?.[0]?.[tab - 1]?.[ind]?.[index]}
                                                    >
                                                        {
                                                            element?.map((elInEl: any) => {
                                                                return (
                                                                    <div
                                                                        key={idGen()}
                                                                    >
                                                                        {renderData(createBuilderData(componentsData, elInEl))}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>)
}
