import Head from 'next/head';
import React, {useRef} from 'react'

export default function AccordionLIst(props) {
    const contentEl = useRef();
    const {handleToggle, active, faq} = props;
    const {title, id, description, setStyles,} = faq;


    let schemaString = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
            {
                "@type": "Question",
                "name": title,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": description
                }
            }
        ]
    }
    return (
        <>
            <Head>
                {schemaString &&
                    <script type="application/ld+json">
                        {JSON.stringify(schemaString)}
                    </script>
                }
            </Head>
            <div className="rc-accordion-card">
                <div className="rc-accordion-header">
                    <div className={`rc-accordion-toggle p-3 ${active === id ? 'active' : ''}`}
                         onClick={() => handleToggle(id)}>
                        <h5 className="rc-accordion-title" style={props?.setStyles}>{title}</h5>
                        <div className=' rc-accordion-icon'></div>
                    </div>
                </div>
                <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={
                    active === id
                        ? {height: contentEl.current.scrollHeight}
                        : {height: "0px"}
                }>
                    <div className="rc-accordion-body">
                        <p className='mb-0' style={props?.setStyles}>{description}</p>
                    </div>
                </div>
            </div>
        </>
    )
}